import { toast } from 'react-toastify';
import api from '../../api';

const notify = (type, message, component = null) => {
  const tostProp = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const payload = {
    status: type,
    message,
  };

  api.postLog(payload);

  if (component) {
    return toast(component, tostProp);
  }
};

export default notify;